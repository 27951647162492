import React from "react";
import { Navbar } from "../components";
import { Contact } from "../containers";
import CoursePageContent from "../components/coursePage/CoursePageEnglishContent";

function CourseEnglishPage() {
  return (
    <div>
      <div className="z-top">
        <Navbar />
      </div>
      <CoursePageContent />
      <Contact />
    </div>
  );
}

export default CourseEnglishPage;
