import React from "react";
import { Navbar } from "../components";
import { Contact } from "../containers";
import GdprPageContent from "../components/gdprPage/GdprPageContent";

function GdprPage() {
  return (
    <div>
      <div className="z-top">
        <Navbar />
      </div>
      <GdprPageContent />
      <Contact />
    </div>
  );
}

export default GdprPage;
