import React from "react";
import { CurvedArrow, Fb, Ig, Map } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "../footer/Footer";
import { location, zoomLevel } from "../../constants/constants";
import "./contact.css";

const Contact = ({ contactOption }) => {
  return (
    <div className="bl_contact section_shadow">
      <div className="bl_contact-gmaps">
        <Map location={location} zoomLevel={zoomLevel} />
      </div>
      <div className="bl_contact-information">
        {
        contactOption &&
          <div className="bl_contact-information_option div_shadow">
            {contactOption} <CurvedArrow />
          </div>
        }
        <h2>Ako nás kontaktovať?</h2>
        <div className="bl_contact-information_socials">
          <a href="https://www.facebook.com/Bratilingua/" target="_blank" rel="noreferrer">
            <Fb />
          </a>
          <a href="https://www.instagram.com/bratilingua/" target="_blank" rel="noreferrer">
            <Ig />
          </a>
        </div>
        <div className="bl_contact-information_contact">
          <div>
            <FontAwesomeIcon icon="fa-regular fa-envelope" />
            <p>info@bratilingua.sk</p>
          </div>
          <div>
            <FontAwesomeIcon icon="fa-regular fa-contact-book" />
            <p>+421 950 726 476</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
