import React from "react";
import { menuItems } from "../../components/navbar/menuItems";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import logo from '../../assets/Bratilingua-full-white-thickLine.svg';
import "./footer.css";
import { scrollToTop } from "../../utils/utils";

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <div className="bl_footer">
      <div className="bl_footer-menu_links">
        {menuItems.map((menuItem) => {
          if (menuItem.submenu) {
            return (
              <div className="bl_footer-menu_container" key={menuItem.title}>
                <Link to={menuItem.url} onClick={scrollToTop}>{menuItem.title}</Link>
                <ul className="bl_footer-submenu_items">
                  {menuItem.submenu.map((submenuItem) => (
                    <li className="bl_footer-submenu_items-item" key={submenuItem.title}>
                      <FontAwesomeIcon icon="fa-regular fa-square-caret-right" />
                      <Link to={submenuItem.url} onClick={scrollToTop}>{submenuItem.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            );
          } else {
            return (
              <div className="bl_footer-menu_container" key={menuItem.title}>
                <Link to={menuItem.url} onClick={scrollToTop}>{menuItem.title}</Link>
              </div>
            );
          }
        })}
      </div>
      <div className="bl_footer-menu_rest">
        <Link to="/" onClick={scrollToTop}><img src={logo} alt="" /></Link>
        <div className="bl_footer-copyright">
          <p className="bl_footer-copyright_privacyPolicy"><Link to="/gdpr" onClick={scrollToTop}>obchodné podmienky a GDPR</Link></p>
          <p>copyright 2023 - {currentYear} Bratilingua</p>
          <p>created by <Link id="createdBy" to="https://gunnarvanremoortere.be/v2/" target="_blank">Gunnar Van Remoortere</Link></p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
