import React from "react";
import BasicTable from "./timetable/TimeTable";
import CampForm from "./form/CampForm";
import { useForm } from "react-hook-form";
import 'react-toastify/dist/ReactToastify.css';
import "./campPage.css";

function Camp() {
  const {register, handleSubmit, formState: { errors }, setValue, reset, watch} = useForm();

  return (
    <div className="bl_camp-wrapper section__padding-page">
      <h1>Denný letný tábor</h1>
      <div className="bl_camp-content_head">
        <div>
          <p><b className="text-orange" style={{ fontSize: "1.2rem", lineHeight: "1rem" }}>Predstavte si to:</b> zábavné jazykové hry, vzrušujúce aktivity vonku a kopec smiechu každý deň. Prechádzka k jazeru Veľký Dráždiak, opekačka, anglický filmový deň a mnoho ďalších.</p>
          <p>Náš tábor je vhodný pre deti <b>od 6 do 12 rokov</b>. Počas hodín angličtiny budú deti rozdelené do viacerých skupín podľa ich vekovej kategórie.</p>
          <div className="flex-col">
            <p style={{ fontSize: "1.2rem" }}><b className="text-orange">KDE?</b> V Petržalskom centre pre rodiny</p>
            <p style={{ fontSize: "1.2rem" }}><b className="text-orange">KEDY?</b> 05.08.2024 - 09.08.2024 <b>ALEBO</b> 12.08.2024 - 16.08.2024</p>
          </div>
          <div className="flex-col">
            <p>Cena nášho tábora je <b>180€</b> (v prípade 2 detí <b>160€</b>).
              Klienti jazykovej školy majú nárok na zľavu vo výške <b>10%</b> <i>(neplatí v prípade prihlásenia 2 detí)</i>. Cena anglického tábora zahŕňa: hodiny angličtiny vrátane študijných materiálov, celodennú starostlivosť, desiatu a obed, pitný režim.
            </p>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <p><b className="text-orange" style={{ fontSize: "1.2rem", lineHeight: "1rem" }}>Prečo si vybrať tábor Bratilingua?</b></p>
            <ul>
              <li className="orange-dot">
                <b>Skúsení lektori:</b> Naše lektorky majú bohaté skúsenosti s prácou s deťmi všetkých vekových kategórií a z rôznych prostredí. Sú vyškolené v oblasti vývoja detí, riešenia konfliktov a núdzových postupov, aby s istotou a profesionalitou zvládli každú situáciu.
              </li>
              <li className="orange-dot">
                <b>Bezpečnosť na prvom mieste:</b> Bezpečnosť vášho dieťaťa je našou prioritou. Počas celého táboru nás bude sprevádzať zdravotník, ktorý bude dohliadať na bezpečnosť a zdravotnú spôsobilosť všetkých detí.
              </li>
              <li className="orange-dot">
                <b>Osobný prístup:</b> Vďaka nízkemu počtu táborníkov uprednostňujeme individuálnu pozornosť pre každé dieťa.
              </li>
              <li className="orange-dot">
                <b>Bezkonkurenčná cena:</b> Celodenný tábor vás môže stáť len 160€!
              </li>
            </ul>
          </div>
        </div>
        <div>
          <BasicTable />
        </div>
      </div>
      <h2 style={{ marginBottom: "0", marginTop: "3rem" }}>Záväzná prihláška</h2>
      <CampForm
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        setValue={setValue}
        reset={reset}
        watch={watch}
      />
    </div>
  );
}

export default Camp;
