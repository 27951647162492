export const galleryImages = [
  {
    src: require("../../assets/gallery/office-new.jpg"),
    alt: "in person english class",
    title: "The new decoration in the office."
  },
  {
    src: require("../../assets/gallery/bratilingua-new-office-outside.jpg"),
    alt: "Bratilingua office, outside front view",
    title: "The outside front of the new office building of Bratilingua."
  },
  {
    src: require("../../assets/gallery/online-class-2.png"),
    alt: "online english class 2",
    title: "English teacher in an online class with a student."
  },
];
