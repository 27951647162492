import React, { useState } from "react";
import PhotoAlbum from "react-photo-album";
import photos from "./galleryPhotos";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./galleryPage.css";

const GalleryPageContent = () => {
  const [index, setIndex] = useState(-1);

  const extraStyle = {
    width: "100%",
    WebkitBoxShadow: "0px 6px 10px rgba(0, 0, 0, 0.1)",
    MozBoxShadow: "0px 6px 10px rgba(0, 0, 0, 0.1)",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, .4)",
    WebkitTransition: "all 0.2s linear",
    MozTransition: "all 0.2s linear",
    transition: "all 0.2s linear",
    cursor: "pointer",
    padding: "0",
    border: ".3rem solid #FAF9F6",
    borderRadius: ".3rem"
  }

  const tagStyle = {
    position: "absolute",
    bottom: ".5rem",
    right: ".3rem",
    padding: ".5rem 1rem",
    backgroundColor: "green",
    borderRadius: "1rem",
    fontSize: ".8rem",
    color: "white",
    fontWeight: "600"
  }

  const renderPhoto = ({ imageProps: { alt, title, style, ...restImageProps } }) => (
    <div style={{ position: "relative" }}>
      {title && <a href={title} target="_blank" rel="noreferrer"><span style={{ ...tagStyle }}>Kliknite tu</span></a>}
      <img alt={alt} style={{ ...style, ...extraStyle }} {...restImageProps} />
    </div>
  );
  return (
    <div className="bl_galleryPage-wrapper section__padding-page">
        <PhotoAlbum
            layout="masonry"
            renderPhoto={renderPhoto}
            spacing={20}
            photos={photos}
            onClick={({ index }) => setIndex(index)}
            columns={4}
        />
        <Lightbox
            slides={photos}
            open={index >= 0}
            index={index}
            close={() => setIndex(-1)}
            plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
        />
    </div>
  );
};

export default GalleryPageContent;
