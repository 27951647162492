import React from "react";
import { Navbar } from "../components";
import { Contact } from "../containers";
import CoursePageSpanishContent from "../components/coursePage/CoursePageSpanishContent";

function CourseSpanishPage() {
  return (
    <div>
      <div className="z-top">
        <Navbar />
      </div>
      <CoursePageSpanishContent />
      <Contact />
    </div>
  );
}

export default CourseSpanishPage;
