import React from 'react';
import './errorText.css';

const ErrorText = ({ message }) => {
  return (
    <span className='bl_errorText'>
      {message}
    </span>
  )
}

export default ErrorText