import React from "react";
import { Navbar } from "../components";
import AboutPageContent from "../components/aboutPage/AboutPageContent";
import { Contact } from "../containers";

function AboutPage() {
  return (
    <div>
      <div className="z-top">
        <Navbar />
      </div>
      <AboutPageContent />
      <Contact />
    </div>
  );
}

export default AboutPage;
