import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { galleryImages } from "./galleryImages";
import "./gallery.css";
import { scrollToTop } from "../../utils/utils";

const Gallery = () => {
  return (
    <div className="bl_gallery section__padding section_shadow">
      <h2>Naša galéria</h2>
      <div className="bl_gallery-container">
        {galleryImages.map((image, i) => (
          <div key={i} className="bl_gallery-container_column">
            <img src={image.src} alt={image.alt} />
          </div>
          ))}
      </div>
      <Link className="bl_gallery-container_link" to={"/galeria"} onClick={scrollToTop}>
        Pozrite si celú galériu
        <FontAwesomeIcon icon="fa-regular fa-arrow-alt-circle-left" />
      </Link>
    </div>
  );
};

export default Gallery;
